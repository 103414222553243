import { DAY_TIME_MILLISEC } from './constants'

export const COMPOUNDING_MONTHLY = 'monthly'
export const COMPOUNDING_QUARTERLY = 'quarterly'
export const COMPOUNDING_HALF_YEARLY = 'half_yearly'
export const COMPOUNDING_YEARLY = 'yearly'

const CompoundingInterval = {
	[COMPOUNDING_MONTHLY]: 12,
	[COMPOUNDING_QUARTERLY]: 4,
	[COMPOUNDING_HALF_YEARLY]: 2,
	[COMPOUNDING_YEARLY]: 1,
}

export type CompoundingTypeArgs =
	| typeof COMPOUNDING_MONTHLY
	| typeof COMPOUNDING_QUARTERLY
	| typeof COMPOUNDING_HALF_YEARLY
	| typeof COMPOUNDING_YEARLY

export function calculateInterest({
	amount,
	interest,
	tenure,
	compoundingType,
}: {
	amount: number
	interest?: number
	tenure?: string
	compoundingType: CompoundingTypeArgs
}) {
	let mAmt = 0,
		fdGains = 0
	if (
		tenure !== null &&
		tenure !== undefined &&
		interest !== null &&
		interest !== undefined &&
		amount !== 0
	) {
		let year: number, month: number, days: number

		if (tenure.split(' ')[1]) {
			// both year and month : 1Y 6M, 6M 1D
			year = tenure.includes('Y') ? Number(tenure.split('Y')[0]) : 0
            month = getMonth(tenure)

            days = tenure.split(' ')[1].includes('D')
                ? Number(tenure.split(' ')[1].split('D')[0])
                : 0

            // suryoday - 6M 1D
			if(year === 0 && month !== 0 && days !== 0){
                let totalDays = ( year * 365 ) + ((month / 12) * 365) + days 
				mAmt = Number(
					(
						amount *
						(1 + interest / (100 * CompoundingInterval[compoundingType])) **
							(CompoundingInterval[compoundingType] * (totalDays / 365))
					).toFixed(0),
				)
				fdGains = Number((mAmt - amount).toFixed(0))

				return { mAmt, fdGains }
			}

			//console.log('Y M D: ', year, month, days)

			if (days && days !== 0) {
                let totalDays = ( year * 365 ) + (month * 30) + days 
				mAmt = Number(
					(
						amount *
						(1 + interest / (100 * CompoundingInterval[compoundingType])) **
							(CompoundingInterval[compoundingType] * (totalDays / 365))
					).toFixed(0),
				)
				fdGains = Number((mAmt - amount).toFixed(0))

				return { mAmt, fdGains }
			}

			let totalMonths = year * 12 + month

			mAmt = Number(
				(
					amount *
					(1 + interest / (100 * CompoundingInterval[compoundingType])) **
						(CompoundingInterval[compoundingType] * (totalMonths / 12))
				).toFixed(0),
			)
			fdGains = Number((mAmt - amount).toFixed(0))

			return { mAmt, fdGains }
		} else {
			// single year or month or Day : 1Y, 18M, 201D

			year = tenure.includes('Y') ? Number(tenure.split('Y')[0]) : 0
			month = tenure.includes('M') ? Number(tenure.split('M')[0]) : 0
			days = tenure.includes('D') ? Number(tenure.split('D')[0]) : 0

			// Quaterly Compounding ( that's why 400 & 4 )
			if (year && year !== 0) {
				mAmt = Number(
					(
						amount *
						(1 + interest / (100 * CompoundingInterval[compoundingType])) **
							(CompoundingInterval[compoundingType] * year)
					).toFixed(0),
				)
				fdGains = Number((mAmt - amount).toFixed(2))

				return { mAmt, fdGains }
			}

			if (month && month !== 0) {
				mAmt = Number(
					(
						amount *
						(1 + interest / (100 * CompoundingInterval[compoundingType])) **
							(CompoundingInterval[compoundingType] * (month / 12))
					).toFixed(0),
				)
				fdGains = Number((mAmt - amount).toFixed(0))

				return { mAmt, fdGains }
			}

			if (days && days !== 0) {
				mAmt = Number(
					(
						amount *
						(1 + interest / (100 * CompoundingInterval[compoundingType])) **
							(CompoundingInterval[compoundingType] * (days / 365))
					).toFixed(0),
				)
				fdGains = Number((mAmt - amount).toFixed(0))

				return { mAmt, fdGains }
			}
		}
	}

	return { mAmt, fdGains }
}

export function getTenureYearFormatFromDayMonthYear({day, month, year}: {day: number, month: number, year: number}){

	if(year !== 0 && month !== 0 && day !== 0){
        return `${year}Y ${month}M ${day}D`
	}else{
       if(year !== 0 && month !== 0){
        return `${year}Y ${month}M`
	   }else if(year !== 0 && day !== 0){
		return `${year}Y ${day}D`
	   }else if(month !== 0 && day !== 0){
		return `${month}M ${day}D`
	   }else if(year !== 0){
		return `${year}Y`
	   }else if(month !== 0){
		return `${month}M`
	   }else if(day !== 0){
		return `${day}D`
	   }else{
		return `1Y`
	   }
	}
}

export function getDayMonthYearFromTenure(tenure: string){

	if(tenure.split(' ').length === 1){
		// single year or month or day
		if(tenure.includes('Y')){
			return { year: Number(tenure[0]), month: 0, day: 0}
		}else if(tenure.includes('M')){
			return { year: 0, month: Number(tenure[0]), day: 0}
		}else if(tenure.includes('D')){
			return { year: 0, month: 0, day: Number(tenure[0])}
		}else{
			return { year: 0, month: 0, day: 0}
		}
	}else{
		// both year and month : 1Y 6M, 6M 1D
		let year = tenure.includes('Y') ? Number(tenure.split('Y')[0]) : 0
		let month = getMonth(tenure)

		let day = tenure.split(' ').length >= 2 && tenure.split(' ')[1].includes('D')
                ? Number(tenure.split(' ')[1].split('D')[0])
                : tenure.split(' ').length >= 3 && tenure.split(' ')[2].includes('D') 
					? Number(tenure.split(' ')[2].split('D')[0])
					: 0

	    return {
			year,
			month,
			day
		}
	}
}

function getMonth(tenure: string){
	let month = 0;
	if(tenure.includes('M')){
		month = tenure.split(' ')[0].includes('M') ? Number(tenure.split(' ')[0].split('M')[0]) : 0
		if(month === 0){
			month = tenure.split(' ')[1].includes('M') ? Number(tenure.split(' ')[1].split('M')[0]) : 0
		}
	}

	return month
}

export function calculateInterestFincare({
	amount,
	interest,
	tenure,
	compoundingType,
}: {
	amount: number
	interest?: number
	tenure?: string
	compoundingType: CompoundingTypeArgs
}) {
	let mAmt = 0,
		fdGains = 0
	if (
		tenure !== null &&
		tenure !== undefined &&
		interest !== null &&
		interest !== undefined &&
		amount !== 0
	) {
		let days = Number(tenure.split('D')[0])

		mAmt = Number(
			(
				amount *
				(1 + interest / (100 * CompoundingInterval[compoundingType])) **
					(CompoundingInterval[compoundingType] * (days / 365))
			).toFixed(0),
		)
		fdGains = Number((mAmt - amount).toFixed(0))

		return { mAmt, fdGains }
	}

	return { mAmt, fdGains }
}

export function createYearFormatFromMonth(noOfMonth: number) {
	let year = Math.floor(noOfMonth / 12)
	let month = Math.floor(noOfMonth % 12)

	if (month === 0) {
		return `${year}Y`
	}
	if (year < 1) {
		return `${month}M`
	}
	return `${year}Y ${month}M`
}

export function calculateYield(
	amount: number,
	tenure: string | undefined,
	gains: number,
) {
	// if the tenure in year and month format
	let yieldPercent: number
	let year: number, month: number, days: number

	if (!tenure) return '0.00'

	if (tenure.split(' ')[1]) {
		// both year and month : 1Y 6M
		year = tenure.includes('Y') ? Number(tenure.split('Y')[0]) : 0
		month = tenure.split(' ')[1].includes('M')
			? Number(tenure.split(' ')[1].split('M')[0])
			: 0

		//console.log('Y M : ', year, month)

		let totalMonths = year * 12 + month

		yieldPercent = (gains / amount / (totalMonths / 12)) * 100

		return yieldPercent.toFixed(2)
	} else {
		// single year or month or Day : 1Y, 18M, 201D

		year = tenure.includes('Y') ? Number(tenure.split('Y')[0]) : 0
		month = tenure.includes('M') ? Number(tenure.split('M')[0]) : 0
		days = tenure.includes('D') ? Number(tenure.split('D')[0]) : 0

		//console.log('Y M : ', year, month)

		if (days && days !== 0) {
			yieldPercent = (gains / amount / (days / 365)) * 100
			return yieldPercent.toFixed(2)
		}

		if (month && month !== 0) {
			yieldPercent = (gains / amount / (month / 12)) * 100
			return yieldPercent.toFixed(2)
		}

		if (year && year !== 0) {
			yieldPercent = (gains / amount / year) * 100
			return yieldPercent.toFixed(2)
		}

		return '0.00'
	}
}

export function calculateAgeFromDob(dob: string) {
	// dob is in DD-MM-YYYY format
	const dobArr = dob.split('-')

	const dobChangedFormat = `${dobArr[2]}-${dobArr[1]}-${dobArr[0]}` // new format in YYYY-MM-DD format

	const diffMs = Date.now() - new Date(dobChangedFormat).getTime()
	const ageDateTime = new Date(diffMs)
	const ageInYear = ageDateTime.getUTCFullYear() - 1970

	return Math.abs(ageInYear)
}

export function convertDateToFullDateNotation(dob: string) {
	// it take dob as input : 24-4-2005 -> 24-04-2005, 4-4-2004 -> 04-04-2004

	const dobArr = dob.split('-')

	if(dobArr[0].length === 4){
		// i.e date in YYYY-MM-DD format

		const newDay = dobArr[2].length === 1 ? `0${dobArr[2]}` : dobArr[2]
		const newMonth = dobArr[1].length === 1 ? `0${dobArr[1]}` : dobArr[1]
	
		return `${dobArr[0]}-${newMonth}-${newDay}`
	}

	const newDay = dobArr[0].length === 1 ? `0${dobArr[0]}` : dobArr[0]
	const newMonth = dobArr[1].length === 1 ? `0${dobArr[1]}` : dobArr[1]

	return `${newDay}-${newMonth}-${dobArr[2]}`
}

export function getDayMonthYearFromDate(dob: string) {
	// it take dob as input : 24-4-2005 -> 24-04-2005, 4-4-2004 -> 04-04-2004
	// output : { day: '24', month: '4', year: '2004' }

	const dobArr = dob.split('-')

	if(dobArr[0].length === 4){
		// i.e date in YYYY-MM-DD format

		const newDay =
		dobArr[2].length === 1
			? `${dobArr[2]}`
			: dobArr[2].slice(0, 1) === '0' // 01 - 09
			  ? dobArr[2].slice(1)
			  : dobArr[2]
		const newMonth =
			dobArr[1].length === 1
				? `0${dobArr[1]}`
				: dobArr[1].slice(0, 1) === '0' // 01 - 09
				? dobArr[1].slice(1)
				: dobArr[1]

		return {
			day: newDay,
			month: newMonth,
			year: dobArr[0],
		}

	}

	const newDay =
		dobArr[0].length === 1
			? `${dobArr[0]}`
			: dobArr[0].slice(0, 1) === '0' // 01 - 09
			  ? dobArr[0].slice(1)
			  : dobArr[0]
	const newMonth =
		dobArr[1].length === 1
			? `0${dobArr[1]}`
			: dobArr[1].slice(0, 1) === '0' // 01 - 09
			  ? dobArr[1].slice(1)
			  : dobArr[1]

	return {
		day: newDay,
		month: newMonth,
		year: dobArr[2],
	}
}

export function getFirstNameFromName(fullName: string) {
	const namesArr = fullName.split(' ')
	let middleName = ''
	if (namesArr.length > 2) {
		for (let i = 1; i <= namesArr.length - 2; i++) {
			if (i === namesArr.length - 2) {
				middleName = middleName + namesArr[i]
			} else {
				middleName = middleName + namesArr[i] + ' '
			}
		}
	}
	let firstName = namesArr[0]
	let lastName = namesArr[namesArr.length - 1]

	return { firstName, middleName, lastName }
}

export function getMaturityDate(tenure: string) {
	const currentTime = new Date().getTime()
	let maturityDate
	if (tenure.includes('D')) {
		const noOfDays = tenure.split('D')[0]
		maturityDate = new Date(currentTime + Number(noOfDays) * DAY_TIME_MILLISEC)
	} else {
		const year = tenure.split('Y')[0]
		const monthPart = tenure.split(' ')[1]

		if (monthPart && monthPart !== '') {
			const monthNo = monthPart.split('M')[0]
			const totalDays =
				Number(year) * 365 + Math.ceil((Number(monthNo) / 12) * 365)
			maturityDate = new Date(
				currentTime + Number(totalDays) * DAY_TIME_MILLISEC,
			)
		} else {
			const totalDays = Number(year) * 365
			maturityDate = new Date(
				currentTime + Number(totalDays) * DAY_TIME_MILLISEC,
			)
		}
	}

	return maturityDate.toISOString().split('T')[0]
}

export function getCurrentFormattedDate() {
	// output in - yyyy-mm-dd
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    return `${year}-${month}-${day}`;
}

export function getPastOrFutureFromCurrentDate(yearsAgoOrFuture: number, isFuture: boolean = false) {
    const date = new Date();
    date.setTime(isFuture ? date.getTime() + (yearsAgoOrFuture) * 365 * 24 * 60 * 60 * 1000 : date.getTime() - (yearsAgoOrFuture) * 365 * 24 * 60 * 60 * 1000); // Subtract years in milliseconds

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function getDaysFromTenure(tenure: string) {
	let noOfDays
	if (tenure.includes('D')) {
		noOfDays = tenure.split('D')[0]
	} else {
		const year = tenure.split('Y')[0]
		const monthPart = tenure.split(' ')[1]

		if (monthPart && monthPart !== '') {
			const monthNo = monthPart.split('M')[0]
			noOfDays = Number(year) * 365 + Math.ceil((Number(monthNo) / 12) * 365)
		} else {
			noOfDays = Number(year) * 365
		}
	}

	return Number(noOfDays)
}

export function getCurrentValue(fd: {
	totalGains: number
	bookedDate: string
	tenure: string
}) {
	const totalDaysOfTenure = getDaysFromTenure(fd.tenure)
	const perDayGain = fd.totalGains / totalDaysOfTenure

	const bookedDateTime = new Date(fd.bookedDate).getTime()
	const currentDateTime = new Date().getTime()
	const diffTime = currentDateTime - bookedDateTime
	const noOfDaysAfterBooked = Math.floor(diffTime / DAY_TIME_MILLISEC)

	return Math.ceil(noOfDaysAfterBooked * perDayGain)
}
